module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"sappzo","short_name":"sappzo","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"fullscreen","icon":"static/images/favicon.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-156160647-1","head":true,"optimizeId":"GTM-P2QCKBG","cookieDomain":"auto"},
    }]
